import React from 'react';
import * as styles from './ProcessCard.module.scss';

interface ProcessCardProps {
    number: string;
    title: string;
    text: string;
}

const ProcessCard = ({ number, title, text }: ProcessCardProps) => {

    return <div className={styles.greyBox}>
        <h1 className={styles.h1}>{number}</h1>
        <h2>{title}</h2>
        <p className={styles.p}>{text}</p>
    </div>
}

export default ProcessCard;