import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../Layout";
import Hero from "../../components/Hero";
import IntroBlock from "../../components/IntroBlock";
import { breadcrumbsClasses } from "@mui/material";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby";
import AccentBar from "../../components/AccentBar";
import { Container, Row, Col} from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import GreyBox from "../../components/GreyBox"; 
import OrangeBox from "../../components/OrangeBox";
import QuoteText from "../../components/quoteText";
import diagram from "../../images/pages/services/carbon-opdiagram.svg"; 
import ProcessCard from "../../components/ProcessCard";
import CTABox from "../../components/CTABox";


const CarbonOptimisation = () => {
  return (
    <Layout>
      <SEO title="Carbon Optimisation" />

      <Hero imageName="carbonoptimisationBackground">
        <AccentBar>
          <h1 className="whiteText servicesHeading">
            Carbon <br />
            Optimisation
          </h1>
        </AccentBar>
      </Hero>

      <section>
        <Breadcrumb>
        <Breadcrumb.Item as={Link} to="/services/">
          Services 
        </Breadcrumb.Item>
        <Breadcrumb.Item>Carbon Optimisation</Breadcrumb.Item>
        </Breadcrumb>
        <Container fluid>
          <Row>
            <Col xs={12} sm={6}>
              <h2 style={{ paddingTop: 50 }}>Why is carbon regulation an Important business issue?</h2>
              <span className="smallDividerLeft"></span>
              <p>
                There are clear indications from international climate change negotiations that the number of national, regional and sectoral carbon markets will dramatically increase over the next decade, as regulators turn to the market as the most cost effective mechanism to control GHGs.
              </p>
            </Col>
            <Col
              xs={12}
              sm={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "60px"
              }}
            >
              <StaticImage
                src="../../images/pages/services/emissions.jpg"
                height={350}
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col>
              <GreyBox
                xs={12}
                sm={4}>
                <h4 style={{ marginTop: -60, fontWeight: 750 }}>Increased Costs</h4>
                <p style={{}}>Carbon pricing is increasing the cost of doing business, both directly on your emissions and through higher energy prices.</p>
              </GreyBox>
            </Col>
            <Col>
              <GreyBox
                xs={12}
                sm={4}>
                <h4 style={{ marginTop: -60, fontWeight: 750 }}>Fines & Penalties</h4>
                <p>Many emerging schemes provide fines and penalties for non-compliance with potentially serious financial implications.</p>
              </GreyBox>
            </Col>
            <Col>
              <GreyBox
                xs={12}
                sm={4}>
                <h4 style={{ marginTop: -60, fontWeight: 750 }}>Competitive Advantage</h4>
                <p>When competing in a global market place, the response to carbon regulation is fast becoming a source of competitive advantage.</p>
              </GreyBox>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Row>
          <Col>
            <h3 className="text-center" style={{ fontWeight: 525, paddingBottom: 70 }}>How can TBL assist you in driving business <br /> value through carbon optimisation?</h3>
          </Col>
        </Row>
        <Row style={{ display: "flex"}} >
          <Col
          xs={12}
          sm={4}
          >
            <OrangeBox>
              <p style={{ color: 'white' }}>We have a clear track record; our teams are working with leading international organisations around the world and have exceptional credentials.</p>
            </OrangeBox>
          </Col>
          <Col 
          xs={12}
          sm={4}
          style={{flexGrow: 1 }}>
            <StaticImage
              src="../../images/pages/services/carbon-op3.jpg"
              imgStyle={{ objectFit: 'cover', height: '100%', padding: 10 }}  
              style={{height: '100%', padding: 10}}
              alt="" 
            />
          </Col>
          <Col
            xs={12}
            sm={4}>
            <OrangeBox>
              <p style={{ color: "white" }}>We understand the commercial imperative: we combine policy insight with scenario planning, risk management and tax structuring to address your pressing issue.</p>
            </OrangeBox>
          </Col>
        </Row>
      </section>

      <section>
        <QuoteText text="There are six key types of risk to business from climate change: physical risk, regulatory risk, reputational risk, competitive risk, social risk and litigation risk." />
      </section>

      <section>
        <Row>
          <Col>
            <h4 className="text-center" style={{ fontWeight: 525 }}>Our approach to developing a price on carbon <br /> within your organisation</h4>
          </Col>
        </Row>
        <Row>
          <Col
          xs={12}
          sm={12}
          md={12} 
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
            width: "60%",
            margin: "auto", 
          }}
          >
            <img src={diagram} style={{ alignItems: "center", margin: "auto" }}/> 
          </Col>
        </Row>
      </section>

      <section>
        <Row>
          <Col>
            <h3 className="text-center" style={{ fontWeight: 525, paddingBottom: 50, marginTop: -50 }} >Challenges to consider when developing your roadmap</h3>
          </Col>
        </Row> 
        <Container fluid>
          <Row style={{ display: "flex" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <ProcessCard
                number="1"
                title="Strategy"
                text="How will existing and emerging carbon regulation impact the overall business strategy?"
              />
            </Col>
            <Col
            style={{
              display: "flex",
              flexDirection: 'column',
              justifyContent: "flex-start",
            }}
            >
              <ProcessCard
              number="2"
              title="Business Case"
              text="What is the commercial case for an optimal response to carbon regulation?"
              />
            </Col>
            <Col
            style={{
              display:"flex",
              flexDirection:"column",
              justifyContent: "flex-start",
            }}
            >
              <ProcessCard
              number="3"
              title="Implementation"
              text="What is the most effective and achievable operating model?"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default CarbonOptimisation;
