import React from 'react';
import * as styles from './quoteText.module.scss' 
import QuoteGlyph from "../../images/svgs/quote-left-duotone.svg";
import QuoteGlyphRight from "../../images/svgs/quote-right-duotone.svg";


interface QuoteTextProps {
    text: string;
}

const QuoteText = ({ text }: QuoteTextProps) => {
    return (<div className={styles.quoteText}>
        <img src={QuoteGlyph} className={styles.glyph}/>
        {text}
        <img src={QuoteGlyphRight} className={styles.glyphRight}/>
    </div>)
}

export default QuoteText; 
